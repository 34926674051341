export default {
    state: {
        documentos: [{
            item: 'prueba',
            item2: 'prueba2'
        }],

        actualizarDocumentos: 0,
        actualizarFolders: 0,

        documentos_subidos: 0,

       
      
    },
    mutations: {
        SET_CANTIDAD_DOCUMENTOS_SUBIDOS(state,cantidad){
            state.documentos_subidos = state.documentos_subidos+cantidad
        },
        RESET_DOCUMENTOS_SUBIDOS(state,reset){
            state.documentos_subidos = reset
        }
    },
    actions: {
        async SET_API_DOCUMENTOS_SUBIDOS({state,rootState,dispatch}){
            console.log(state.documentos_subidos);

            console.log(rootState.dataUser);
           
            try {
                
                
              let actualizar = await dispatch('GET_CANTIDAD_DOCUMENTOS_SUBIDOS')   
                
              console.log(actualizar)
                if(actualizar.sucess){
                    actualizar.data = actualizar.data.data
                    if(actualizar.data.results.length==0){
                        let fecha = new Date().toISOString().substr(0, 10);
                        let response = await dispatch('llamado_post',{
                            
                            url:"https://siett.educandote.co/parse/classes/digitalizacion_archivos",
                            body: {
                                documentos_subidos: state.documentos_subidos,
                                fecha_string: fecha,
                                fecha_number: Date.parse(new Date()),
                                empleado: rootState.dataUser.dataUser.objectId
                            },
                            tipo_header: "parse"
                        })
                        console.log(response)
                    }else{
                        let actualizar_cantidad_documentos = actualizar.data.results[0].documentos_subidos + state.documentos_subidos
    
                        let response = await dispatch('llamado_put',{
                            url:"https://siett.educandote.co/parse/classes/digitalizacion_archivos/"+actualizar.data.results[0].objectId,
                            body: {
                                documentos_subidos: actualizar_cantidad_documentos,
                            },
                            tipo_header: "parse"
                        })
    
                        console.log(response)
                    }
                }else{
                    return false
                }
                
            } catch (error) {
                console.log(error) 
                return false              
            }
            return true
        },
        async GET_CANTIDAD_DOCUMENTOS_SUBIDOS({rootState,dispatch}){
            try {
                let fecha = new Date().toISOString().substr(0, 10);
                let buscar = JSON.stringify({
                    empleado: rootState.dataUser.dataUser.objectId,
                    fecha_string: fecha,
                  });
          
                buscar = encodeURIComponent(buscar);
                let actualizar = await dispatch('llamado_get',{
                    url:"https://siett.educandote.co/parse/classes/digitalizacion_archivos?where="+buscar,
                    tipo_header:"parse"
                })
                let response = {
                    sucess: true,
                    error: false,
                    data: actualizar
                }
                return response
            } catch (error) {
                let response = {
                    sucess: false,
                    error: error.message,
                    data: null,
                }
                console.log(error)   
                return response
            }
            
        }
    }
}