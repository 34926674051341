<template>
  <v-app>
    <v-main> 
      <router-view></router-view>
      <v-dialog v-model="$store.state.dialogError" persistent max-width="400">
        <v-sheet>
          <v-container>
            <v-row>
              <v-col>
                <v-container>
                  <v-sheet color="red" rounded="" dark elevation="2">
                    <v-row>
                      <v-col>
                        <div
                          v-text="'Error'"
                          class="headline font-weight-light text-center"
                        ></div>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-container>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <p style="text-align:center">{{$store.state.mensaje}} </p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  @click="$store.state.dialogError = false"
                  color="red"
                  text
                  block
                  rounded
                >
                  Aceptar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-dialog>
      <v-dialog v-model="$store.state.dialogExitoso" persistent max-width="400">
        <v-sheet>
          <v-container>
            <v-row>
              <v-col>
                <v-container>
                  <v-sheet color="green" rounded="" dark elevation="2">
                    <v-row>
                      <v-col>
                        <div
                          v-text="'Exito'"
                          class="headline font-weight-light text-center"
                        ></div>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-container>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <p style="text-align:center">{{$store.state.mensaje}}</p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  @click="$store.state.dialogExitoso = false"
                  color="green"
                  text
                  block
                  rounded
                >
                  Aceptar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-dialog>
      <v-dialog v-model="$store.state.dialogAlerta" persistent max-width="400">
        <v-card>
          <v-card-text>
            <h3 style="text-align: center; color: orange">Alerta</h3>
            <br />
            <v-divider></v-divider>
            <br />
          </v-card-text>
          <v-card-text> {{ $store.state.mensaje }} </v-card-text>
          <v-card-actions>
            <v-btn
              @click="$store.state.dialogAlerta = false"
              color="orange"
              text
              block
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="$store.state.dialogCargando" fullscreen open-delay="0" transition="fade-transition">
        <v-sheet color="rgba(0,0,0,.5)" height="100%">
          <v-container fluid class="fill-height">
            <v-row align="center" justify="center">
              <v-progress-linear
                indeterminate
                class="mx-auto text-center"
                color="blue darken-4"
              />
              <v-col cols="12">
                <div
                  v-text="`Cargando datos... ( ${$store.state.SIETT.contador_carga_documentos} / ${$store.state.SIETT.documentos_a_cargar} ) `"
                  class="header font-weight-ligth text-center white--text"
                ></div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
    </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
//import mixin from './mixins'
//import { imagesToPDF } from '@coderosh/images-to-pdf'

export default {
  name: "App",

  data() {
    return {
      //docValue: 'https://drive1.educandote.co/siett/1004373196_ic6lv42xe4shq8cy6ulr4pyaz/docu.docx',
      //referencias: []
    };
  },
  //mixins: [mixin]  ,
  async  created() {
    
    /*
    var x = new XMLHttpRequest();
    x.open('GET', 'https://drive1.educandote.co/siett/123_oltepobpx274fdkb8csyck7ze/7-TRASPASO-TRASLADO.pdf');
    x.responseType = 'blob';
    x.onload = () => {
        var blob = x.response;
        var fr = new FileReader();
        fr.onloadend = async () => {
            //var dataUrl = fr.result;
            // Paint image, as a proof of concept
            //console.log(fr.result);
           let pdf = await this.dataURItoBlob(fr.result);
           console.log(pdf)
          var pag = pdf.match(/\/Type[\s*]*\/Page[^s]/g).length;
          console.log('Number of Pages:',pag);
        };
        fr.readAsDataURL(blob);
    };
    x.send();
    
    var reader = new FileReader();
      reader.readAsDataURL("");
      reader.onloadend = ()=>{

          
      }

    /*
    const pdfjsLib = require('pdfjs-dist');
    var reader = new FileReader();
      reader.readAsBinaryString(event.archivo[0]);
      reader.onloadend = ()=>{

          this.imagenes_pdf = reader.result.match(/\/Type[\s*]*\/Page[^s]/g).length;
          console.log('Number of Pages:',this.imagenes_pdf);
    
    pdfjsLib.getDocument('https://drive1.educandote.co/siett/123_oltepobpx274fdkb8csyck7ze/7-TRASPASO-TRASLADO.pdf').then(function (doc) {
        var numPages = doc.numPages;
        console.log('# Document Loaded');
        console.log('Number of Pages: ' + numPages);
    });
    */
   
    
    
    //const imagesToPdf = require("images-to-pdf")
    //await imagesToPdf(["", "https://drive1.educandote.co/siett/123_evwntbommih66tl7fxmgn2e36/image0014.jpg"], "result.pdf")
    /*
    
    
      
      let response = await this.$store.dispatch('llamado_get',{
        url:"https://siett.educandote.co/types/collections/pruebaResolucion/documents/search?q="+autor+"&query_by=carpetas&per_page=250&page=1",
        tipo_header: 'indexador'
      })
      console.log(response)
       
     
    let fecha_inicial = Date.parse(new Date('2021-11-27'))
    console.log(fecha_inicial)
    let fecha_final = Date.parse(new Date('2021-11-27 23:59'))
    console.log(fecha_final)

    console.log(new Date(1638218169000));
    console.log(new Date(1638395085000));

    if(fecha_inicial < fecha_final){
      console.log('todo ok')
    }
    let contador = 0
   
  
     for (let page = 1; page < 2; page++) {
       let autor = 'Eduardo alberto vargas bolaño'
        let response = await this.$store.dispatch('llamado_get',{
            url:"https://siett.educandote.co/types/collections/pruebaOrdendepago/documents/search?q="+autor+"&query_by=datos_archivos&per_page=250&page="+page,
            tipo_header: 'indexador'
          })

          console.log(response)

         
          /*
          for (let index = 0; index < response.data.hits.length; index++) {
            for (let index2 = 0; index2 < response.data.hits[index].document.datos_archivos.length; index2++) {
              response.data.hits[index].document.datos_archivos[index2] = JSON.parse(response.data.hits[index].document.datos_archivos[index2])
              
              if(response.data.hits[index].document.datos_archivos[index2].autor=='Bleidis yurianis rodriguez melendez'){
                let fecha = response.data.hits[index].document.datos_archivos[index2].fecha_adjuntado
                if(fecha_inicial < fecha && fecha < fecha_final ){
                  contador++
                }
                
              }
            }
          }
         
          
       
     }
     
      // 
      console.log(contador)
    
      //26 Nov idalmis 607 
      //27 nov idalmis 195
      //27 de nov Johan 170
      //27 de nov Karen 136
      */
     



  },
  mounted(){
    
    
  },
  methods: {
      //
      //caja 1838 
      
  },
};
</script>
