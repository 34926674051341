import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "../store/index";
import router from "../router/index";
import { Drag, Drop } from "vue-drag-drop";
import FlowyPlugin from "./index";
import DemoBlock from "./components/admin/DemoBlock";
import DemoNode from "./components/admin/DemoNode.vue";
import VueApexCharts from "vue-apexcharts";
import Toolbar from "./components/comunes/Toolbar";
import Card from "./components/celulas/Card";
import Paginacion from "./components/atomos/Paginacion";
import Load from "./components/atomos/Load";
import NotFound from "./components/atomos/NotFound";

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
Vue.component("drag", Drag);
Vue.component("drop", Drop);
Vue.use(FlowyPlugin);
Vue.component("DemoBlock", DemoBlock);
Vue.component("DemoNode", DemoNode);
Vue.component("Toolbar", Toolbar);
Vue.component("Card", Card);
Vue.component("Load", Load);
Vue.component("Paginacion", Paginacion);
Vue.component("NotFound", NotFound);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
