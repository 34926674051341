export default{
    //EDUARD
    namespaced: true,
    state:{
        dataUser:[],
        foldersUser:[],
        permisos_usuario: null,
        permisos_caso: {
            
            Aceptar: false,
            Rechazar: false,
            Responder: false,
            Cancelar: false,
            Digitalizar: false
        },
        permisos_buscador: {
            Editar: false,
            Eliminar: false,
            Descargar: false,
            Agregar: false,
            full_query: false,
        },

        private_modules: [],
        auth: false
    },
    mutations: {
        SET_DATA_USER(state, datos){
            state.dataUser = datos;
        },
        SET_FOLDERS_USER(state, folder){
            state.foldersUser.push(folder);  
        },   
        DO_LOGIN(state, user){
            state.auth = true;
            state.dataUser = user; 
        },
        DO_LOGOUT(state){
            state.auth = false;
            state.dataUser = []; 
            state.foldersUser = [];
        },
        
    },
    actions: {
        setDataUser({commit}, datos){
            commit('SET_DATA_USER', datos)
        },
        serFoldersUser({commit}, folders){
            commit('SET_FOLDERS_USER', folders)
        }, 
        mutationss({state,commit,rootState} ){
            console.log(rootState)    
            console.log(state)   
            console.log(commit)   
        }

    },
    getters: {

    }


}