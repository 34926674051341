export default {
    namespaced: true,
    state:{
        tramites: [],
        tipos_de_vehiculos: [],
        form: {
            tramite_seleccionado: {},
            placa: "",
            fecha: "",
            documento_propietario: "",
        },
        contador_carga_documentos: 0,
        documentos_a_cargar: 0,
        
    },
    mutations: {
        
    },
    actions: {
      async GET_TRAMITES({dispatch,state}){
        try {
            
            const get_tramites = await dispatch('llamado_get',{
                url:`https://siett.educandote.co/parse/classes/V1_Tipos_tramites`,
                tipo_header: "parse"
            },{root:true});

            console.log(get_tramites);

            const tramites_parse = JSON.parse(get_tramites.data.results[0].tramites);
            state.tramites = [...tramites_parse.tramites];
            console.log(state.tramites);
        } catch (error) {
            console.log(error);
        }
      },
      async GET_TIPOS_DE_VEHICULOS({dispatch,state}){
        try {
            
            const tipos_de_vehiculos = await dispatch('llamado_get',{
                url:`https://siett.educandote.co/parse/classes/V1_Tipos_de_vehiculos`,
                tipo_header: "parse"
            },{root:true});

            console.log(tipos_de_vehiculos);

            const tipos_de_vehiculos_parse = JSON.parse(tipos_de_vehiculos.data.results[0].tipos_de_vehiculos);
            state.tipos_de_vehiculos = [...tipos_de_vehiculos_parse.tipos_de_vehiculos];
            console.log(state.tipos_de_vehiculos);
        } catch (error) {
            console.log(error);
        }
      },
      async GET_DATA_BY_PLACA({dispatch},placa){
        try {
            
            const get_data_by_placa = await dispatch('llamado_get',{
                url:`https://siett.educandote.co/types/collections/V1_QUERY_DATA/documents/search?q=${placa}&query_by=Placa&per_page=250`,
                tipo_header: "indexador"
            },{root:true});

            console.log(get_data_by_placa);

             let data = await dispatch('utils/SET_DATA_INDEXADOR',get_data_by_placa.data.hits,{root:true});

             console.log(data);

             return data;
     
        } catch (error) {
            console.log(error);
        }
      },

      async GET_DATA_BY_ID({dispatch},id){
        //"https://siett.educandote.co/types/collections/V1_SIETT_DOCUMENT_V3_2/documents/search?q=QFF904&query_by=Placa&filter_by=Placa:["QFF904"]&&estatus:[HABILITADA,INHABILITADA]&per_page=250&page=1"
        try {
            const collections = 'V1_SIETT_DOCUMENT_V3_3' //Colocar nueva colección
            const get_data_by_placa = await dispatch('llamado_get',{
                url:`https://siett.educandote.co/types/collections/${collections}/documents/${id}`,
                tipo_header: "indexador"
            },{root:true});

            console.log(get_data_by_placa);

             //let data = await dispatch('utils/SET_DATA_INDEXADOR',get_data_by_placa.data.hits,{root:true});

             //console.log(data);

             //return data;
            //13555
        } catch (error) {
            console.log(error);
        }
      },

      async REPORTE_GENERAL({dispatch},status){
        try {
            const collections = 'V1_SIETT_DOCUMENT_V3_2'
            const get_reporte = await dispatch('llamado_get',{
                url:`https://siett.educandote.co/types/collections/IMAGENES_${collections}/documents/search?q=${status}&query_by=estado_imagen&per_page=250`,
                tipo_header: "indexador"
            },{root:true});

            console.log(get_reporte);

             let data = get_reporte.data.found

             console.log(data);

             return data;
     
        } catch (error) {
            console.log(error);
        }
      },

      async REPORTE_DIGITALIZADORES({dispatch},status){
        try {
            const collections = 'V1_SIETT_DOCUMENT_V3_2'
            const get_reporte = await dispatch('llamado_get',{
                url:`https://siett.educandote.co/types/collections/IMAGENES_${collections}/documents/search?q=${status}&query_by=estado_imagen&group_by=autor&per_page=250`,
                tipo_header: "indexador"
            },{root:true});

            console.log(get_reporte);

             let reporte = [];

             for (let index = 0; index < get_reporte.data.grouped_hits.length; index++) {
                
                const reporte_por_persona = await dispatch('llamado_get',{
                    url:`https://siett.educandote.co/types/collections/IMAGENES_${collections}/documents/search?q=${get_reporte.data.grouped_hits[index].group_key[0]}&query_by=autor&filter_by=estado_imagen:[${status}]`,
                    tipo_header: "indexador"
                },{root:true});
    
                console.log(reporte_por_persona);

                reporte.push({usuario: get_reporte.data.grouped_hits[index].group_key[0] ,cantidad: reporte_por_persona.data.found  })
             }

             

             return reporte;
     
        } catch (error) {
            console.log(error);
        }
      },
    },
    getters: {

    }


}