import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import dataUser from "../store/modules/dataUser";
Vue.use(VueRouter);
console.log(store);
Vue.use(VueRouter);
console.log(dataUser);

const routes = [
  {
    path: "/",
    name: "login.vue",
    component: () =>
      import(/* webpackChunkName: "login" */ "../src/views/login.vue"),
  },
  {
    path: "/reporteMetaData",
    name: "reporteMetaData.vue",
    component: () =>
      import(/* webpackChunkName: "login" */ "../src/views/Reportes/reporteMetaData.vue"),
  },
  {
    path: "/qr",
    name: "qr",
    component: () =>
      import(/* webpackChunkName: "login" */ "../src/views/Qr/QrView.vue"),
    children: [
      {
        path: "folder/:id",
        name: "Folders",
        props: true,
        meta: {
          name: "Carpetas",
        },
        component: () =>
          import(
            /* webpackChunkName: "home_admin" */ "../src/views/Qr/FolderDocumentsView.vue"
          ),
        beforeEnter: (to, from, next) => {
          if (to.params.data) {
            next();
          } else {
            next({ path: "/qr" });
          }
          return false;
        },
      },
    ],
  },
  {
    path: "/fix_tipo_vehiculo",
    name: "fix_tipo_vehiculo",
    component: () =>
      import(
        /* webpackChunkName: "aud_reportes" */ "../src/views/empalmes/fix_tipo_vehiculo.vue"
      ),
  },
  {
    path: "/recover_data_first_month",
    name: "recover_data_first_month",
    component: () =>
      import(
        /* webpackChunkName: "aud_reportes" */ "../src/views/empalmes/recover_data_first_month.vue"
      ),
  },
  {
    path: "/reporte_tramites_sin_info",
    name: "reporte_tramites_sin_info",
    component: () =>
      import(
        /* webpackChunkName: "aud_reportes" */ "../src/views/empalmes/reporte_tramites_sin_info.vue"
      ),
  },
  {
    path: "/empalmes",
    name: "empalmes",
    component: () =>
      import(
        /* webpackChunkName: "empalmes" */ "../src/views/empalmes/empalmes_siett.vue"
      ),
  },

  {
    path: "/buscar_estado_documentos/:id",
    name: "buscar_estado_documentos_usuario",
    component: () =>
      import(
        /* webpackChunkName: "buscar_estado_documentos" */ "../src/views/buscar_estado_documentos_usuario.vue"
      ),
  },
  {
    path: "/info_usuarios/:id",
    name: "info_usuarios",
    component: () =>
      import(
        /* webpackChunkName: "info_usuarios" */ "../src/views/info_usuarios.vue"
      ),
  },
  {
    path: "/inicio_rol",
    name: "inicio_rol",
    component: () =>
      import(
        /* webpackChunkName: "inicio_rol" */ "../src/views_rol/inicio.vue"
      ),
    beforeEnter: (to, from, next) => {
      console.log(to, from);
      console.log(dataUser.state.permisos_usuario);

      try {
        if (
          dataUser.state.dataUser.rango == "Empleado" ||
          dataUser.state.dataUser.rango == "Supervisor"
        ) {
          next();
        } else {
          next({
            path: "/",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    children: [
      {
        path: "/inicio_rol",
        redirect: "home_rol",
      },

      {
        path: "home_rol",
        name: "home_rol",
        component: () =>
          import(
            /* webpackChunkName: "home_rol" */ "../src/views_rol/home_rol.vue"
          ),
      },
      {
        path: "empalme",
        name: "empalme",
        component: () =>
          import(
            /* webpackChunkName: "inventario_documental" */ "../src/views_rol/digitalizar.vue"
          ),
        beforeEnter: (to, from, next) => {
          console.log(to, from);
          console.log(dataUser.state.permisos_usuario);
          let permiso = false;
          try {
            for (
              let index = 0;
              index < dataUser.state.permisos_usuario.length;
              index++
            ) {
              if (
                dataUser.state.permisos_usuario[index].permiso.permiso ==
                "permiso_1"
              ) {
                permiso = true;
              }
            }
            if (permiso) {
              next();
            } else {
              next({
                path: "/",
              });
            }
          } catch (error) {
            console.log(error);
          }
        },
      },
      {
        path: "inventario_documental",
        name: "inventario_documental",
        component: () =>
          import(
            /* webpackChunkName: "inventario_documental" */ "../src/views_rol/inventario_documental.vue"
          ),
        beforeEnter: (to, from, next) => {
          console.log(to, from);
          console.log(dataUser.state.permisos_usuario);
          let permiso = false;
          try {
            for (
              let index = 0;
              index < dataUser.state.permisos_usuario.length;
              index++
            ) {
              if (
                dataUser.state.permisos_usuario[index].permiso.permiso ==
                "permiso_6"
              ) {
                permiso = true;
              }
            }
            if (permiso) {
              next();
            } else {
              next({
                path: "/",
              });
            }
          } catch (error) {
            console.log(error);
          }
        },
      },
      {
        //Colocar navigations wards de digitalizar
        path: "buscar_documentos",
        name: "buscar_documentos",
        component: () =>
          import(
            /* webpackChunkName: "buscar_documentos" */ "../src/views_rol/buscar_documentos.vue"
          ),
        beforeEnter: (to, from, next) => {
          console.log(to, from);
          console.log(dataUser.state.permisos_usuario);
          let permiso = false;
          try {
            for (
              let index = 0;
              index < dataUser.state.permisos_usuario.length;
              index++
            ) {
              if (
                dataUser.state.permisos_usuario[index].permiso.permiso ==
                "permiso_4"
              ) {
                permiso = true;
              }
            }
            if (permiso) {
              next();
            } else {
              next({
                path: "/",
              });
            }
          } catch (error) {
            console.log(error);
          }
        },
      },
      {
        //Colocar navigations wards de digitalizar
        path: "buscar_estado_documento",
        name: "buscar_estado_documento",
        component: () =>
          import(
            /* webpackChunkName: "buscar_estado_documento" */ "../src/views_rol/buscar_estado_documento.vue"
          ),
        beforeEnter: (to, from, next) => {
          console.log(to, from);
          console.log(dataUser.state.permisos_usuario);
          let permiso = false;
          try {
            for (
              let index = 0;
              index < dataUser.state.permisos_usuario.length;
              index++
            ) {
              if (
                dataUser.state.permisos_usuario[index].permiso.permiso ==
                "permiso_3"
              ) {
                permiso = true;
              }
            }
            if (permiso) {
              next();
            } else {
              next({
                path: "/",
              });
            }
          } catch (error) {
            console.log(error);
          }
        },
      },

      {
        //Colocar navigations wards de digitalizar
        path: "digitalizar",
        name: "digitalizar",
        //component: () => import(/* webpackChunkName: "digitalizar" */ '../src/views_rol/digitalizar.vue'),
        component: () =>
          import(
            /* webpackChunkName: "digitalizar" */ "../src/views_rol/digitalizar_SIETT.vue"
          ),
        beforeEnter: (to, from, next) => {
          console.log(to, from);
          console.log(dataUser.state.permisos_usuario);
          let permiso = false;
          console.log(permiso);
          try {
            for (
              let index = 0;
              index < dataUser.state.permisos_usuario.length;
              index++
            ) {
              console.log(
                dataUser.state.permisos_usuario[index].permiso.permiso
              );
              if (
                dataUser.state.permisos_usuario[index].permiso.permiso ==
                "permiso_1"
              ) {
                permiso = true;
              }
            }
            console.log(permiso);
            if (permiso) {
              next();
            } else {
              next({
                path: "/",
              });
            }
          } catch (error) {
            console.log(error);
          }
        },
      },
      {
        path: "reportes_v1",
        name: "reportes_v1",
        component: () =>
          import(
            /* webpackChunkName: "aud_reportes" */ "../src/views_rol/reportes_v1.vue"
          ),
        beforeEnter: (to, from, next) => {
          console.log(to, from);
          console.log(dataUser.state.private_modules);
          let permiso = true;
          console.log(permiso);
          try {
            for (
              let index = 0;
              index < dataUser.state.private_modules.length;
              index++
            ) {
              if (dataUser.state.private_modules[index].ruta.name == to) {
                permiso = true;
              }
            }
            console.log(permiso);
            if (permiso) {
              next();
            } else {
              next({
                path: "/",
              });
            }
          } catch (error) {
            console.log(error);
          }
        },
      },
      {
        //Colocar navigations wards de iniciar caso
        path: "iniciar_caso",
        name: "iniciar_caso",
        component: () =>
          import(
            /* webpackChunkName: "iniciar_caso" */ "../src/views_rol/iniciar_caso.vue"
          ),
        beforeEnter: (to, from, next) => {
          console.log(to, from);
          console.log(dataUser.state.permisos_usuario);
          let permiso = false;
          try {
            for (
              let index = 0;
              index < dataUser.state.permisos_usuario.length;
              index++
            ) {
              if (
                dataUser.state.permisos_usuario[index].permiso.permiso ==
                "permiso_5"
              ) {
                permiso = true;
              }
            }
            if (permiso) {
              next();
            } else {
              next({
                path: "/",
              });
            }
          } catch (error) {
            console.log(error);
          }
        },
      },
      //---Vistas del usuario empleado ---------------------------------
      {
        path: "revisar_caso/:id",
        name: "revisar_caso_empleado",
        component: () =>
          import(
            /* webpackChunkName: "revisar_caso_empleado" */ "../src/views/viewsEspecialista/revisar_caso_empleado.vue"
          ),
        beforeEnter: (to, from, next) => {
          console.log(to, from);
          console.log(dataUser.state.permisos_usuario);
          let permiso = false;
          try {
            for (
              let index = 0;
              index < dataUser.state.permisos_usuario.length;
              index++
            ) {
              if (
                dataUser.state.permisos_usuario[index].permiso.permiso ==
                  "permiso_2" &&
                dataUser.state.dataUser.rango == "Empleado"
              ) {
                permiso = true;
              }
            }
            if (permiso) {
              next();
            } else {
              next({
                path: "/",
              });
            }
          } catch (error) {
            console.log(error);
          }
        },
      },
      {
        path: "inicio",
        name: "inicio",
        component: () =>
          import(
            /* webpackChunkName: "inicio" */ "../src/views/viewsEspecialista/inicio.vue"
          ),

        children: [
          {
            path: "casos_empleados/:id",
            name: "casos_empleados",
            component: () =>
              import(
                /* webpackChunkName: "casos_empleados" */ "../src/views/viewsEspecialista/casos_empleados.vue"
              ),
          },
          {
            path: "informacion_caso_empleado/:id",
            name: "informacion_caso_empleado",
            component: () =>
              import(
                /* webpackChunkName: "informacion_caso_empleado" */ "../src/views/viewsEspecialista/informacion_caso_empleado.vue"
              ),
          },
        ],
      },
      //------------------Vistas de usuario supervisor---------------------
      {
        //Colocar navigations wards de supervisor
        path: "revisar_caso/:id",
        name: "revisar_caso_supervisor",
        component: () =>
          import(
            /* webpackChunkName: "revisar_caso_supervisor" */ "../src/views/viewsSupervisor/revisar_caso_supervisor.vue"
          ),
        beforeEnter: (to, from, next) => {
          console.log(to, from);
          console.log(dataUser.state.permisos_usuario);
          let permiso = false;
          try {
            for (
              let index = 0;
              index < dataUser.state.permisos_usuario.length;
              index++
            ) {
              if (
                dataUser.state.permisos_usuario[index].permiso.permiso ==
                  "permiso_2" &&
                dataUser.state.dataUser.rango == "Supervisor"
              ) {
                permiso = true;
              }
            }
            if (permiso) {
              next();
            } else {
              next({
                path: "/",
              });
            }
          } catch (error) {
            console.log(error);
          }
        },
      },
      {
        //Colocar navigations wards de supervisor
        path: "home_supervisor",
        name: "home_supervisor",
        component: () =>
          import(
            /* webpackChunkName: "home_supervisor" */ "../src/views/viewsSupervisor/home_supervisor.vue"
          ),
        beforeEnter: (to, from, next) => {
          console.log(to, from);
          console.log(dataUser.state.permisos_usuario);
          let permiso = false;
          try {
            for (
              let index = 0;
              index < dataUser.state.permisos_usuario.length;
              index++
            ) {
              if (
                dataUser.state.permisos_usuario[index].permiso.permiso ==
                  "permiso_2" &&
                dataUser.state.dataUser.rango == "Supervisor"
              ) {
                permiso = true;
              }
            }
            if (permiso) {
              next();
            } else {
              next({
                path: "/",
              });
            }
          } catch (error) {
            console.log(error);
          }
        },

        children: [
          {
            path: "CasosAsignados/:id",
            name: "CasosAsignados",
            component: () =>
              import(
                /* webpackChunkName: "asignados" */ "../src/views/viewsSupervisor/asignados.vue"
              ),
          },
          {
            path: "informacion_caso_supervisor/:id",
            name: "informacion_caso_supervisor",
            component: () =>
              import(
                /* webpackChunkName: "informacion_caso_supervisor" */ "../src/views/viewsSupervisor/informacion_caso_supervisor.vue"
              ),
          },
          {
            path: "informacion_caso_respondido/:id",
            name: "informacion_caso_respondido",
            component: () =>
              import(
                /* webpackChunkName: "informacion_caso_respondido" */ "../src/views/viewsSupervisor/informacion_caso_respondido.vue"
              ),
          },
          {
            path: "CasosRespondidos/:id",
            name: "CasosRespondidos",
            component: () =>
              import(
                /* webpackChunkName: "casos_respondidos" */ "../src/views/viewsSupervisor/casos_respondidos.vue"
              ),
          },
          {
            path: "CasosDelegados/:id",
            name: "CasosDelegados",
            component: () =>
              import(
                /* webpackChunkName: "CasosDelegados" */ "../src/views/viewsSupervisor/delegados_supervisor.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../src/views/admin.vue"),
    beforeEnter: (to, from, next) => {
      console.log(to, from);
      console.log(dataUser.state.dataUser);
      try {
        if (dataUser.state.dataUser.rango !== "admin") {
          next({
            path: "/",
          });
        } else {
          next();
        }
      } catch (error) {
        console.log(error);
      }
    },
    children: [
      {
        path: "/admin",
        redirect: "home_admin",
      },
      {
        path: "crud_graficas/:id",
        name: "crud_graficas",
        component: () =>
          import(
            /* webpackChunkName: "crud_graficas" */ "../src/views/viewsAdmin/crud_graficas.vue"
          ),
      },
      {
        path: "home_admin",
        name: "home_admin",
        component: () =>
          import(
            /* webpackChunkName: "home_admin" */ "../src/views/viewsAdmin/home.vue"
          ),
      },
      {
        path: "crud_documentos",
        name: "crud_documentos",
        component: () =>
          import(
            /* webpackChunkName: "crud_documentos" */ "../src/views/viewsAdmin/crud_documentos.vue"
          ),
      },
      {
        path: "crud_sectores",
        name: "crud_sectores",
        component: () =>
          import(
            /* webpackChunkName: "crud_sectores" */ "../src/views/viewsAdmin/crud_sectores.vue"
          ),
        children: [
          {
            path: "sector/:id",
            name: "sector",
            component: () =>
              import(
                /* webpackChunkName: "rechazados" */ "../src/views/viewsAdmin/sector.vue"
              ),
          },
        ],
      },
      {
        path: "crud_workflows",
        name: "crud_workflows",
        component: () =>
          import(
            /* webpackChunkName: "crud_workflows" */ "../src/views/viewsAdmin/crud_workflows.vue"
          ),
      },
      {
        path: "crud_validaciones",
        name: "crud_validaciones",
        component: () =>
          import(
            /* webpackChunkName: "crud_graficas" */ "../src/views/viewsAdmin/crud_validaciones.vue"
          ),
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition);
    if (to != "/" && to != "") {
      if (to.name != "informacion_caso_supervisor") {
        return { x: 0, y: 0 };
      }
    }
  },
});

export default router;
