export default {
    namespaced: true,
    state: {
        date: null,
        ms: 1000,
        format_base64: "",
        data: [],
    },
    actions: {
      sleep({state},ms){
        
        if(typeof ms == 'undefined'){
          ms = state.ms;
        }
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      GENERATE_FORMAT_DATE(state){
        var d = new Date();
        d = new Date(d.getTime());
        state.date = d.getFullYear().toString()+"-"+((d.getMonth()+1).toString().length==2?(d.getMonth()+1).toString():"0"+(d.getMonth()+1).toString())+"-"+(d.getDate().toString().length==2?d.getDate().toString():"0"+d.getDate().toString())+" "+(d.getHours().toString().length==2?d.getHours().toString():"0"+d.getHours().toString())+":"+((parseInt(d.getMinutes()/5)*5).toString().length==2?(parseInt(d.getMinutes()/5)*5).toString():"0"+(parseInt(d.getMinutes()/5)*5).toString())+":00";
        return state.date;
      },
      FORMAT_BASE64({state},payload){
        try {
          state.format_base64 = btoa(payload) 
        } catch (error) {
          console.log(error);
        }
        return state.format_base64
      },
      SET_DATA_INDEXADOR({state},data){
        
        state.data = [];
        try {
          for (let index = 0; index < data.length; index++) {
            
            state.data.push(data[index].document);
          }
        } catch (error) {
          console.log(error);
        }

        return state.data;

      },

      async SAVE_ACTIVITY_USER({dispatch,rootState},{data,action}){

        try {
          const registro = {
            data: data,
          }
    
          JSON.stringify(registro);
    
          const response = await dispatch('llamado_post',{
              url: "https://siett.educandote.co/parse/classes/V1_REGISTRO_ACTIVIDAD_AUDIDOC",
              body:
                  {
                  userId: rootState.dataUser.dataUser.objectId,
                  identificacion: rootState.dataUser.dataUser.identificacion,
                  nombre_usuario: rootState.dataUser.dataUser.nombre,
                  accion: action,
                  fecha_accion_int: Date.parse(new Date()),
                  data_asociada: registro,
              },
              tipo_header: "parse"
          },{root: true});

          console.log(response);
        } catch (error) {
          console.log(error);
        }
        
      }
     
     
    },
  }
  